import DataService from '../../services/DataService'

const state = () => ({
    datas: [],
    data_campanha: null,
    data_material: null,
    data_imagem: null,
    dataLoading: false,
    showModalDataCampanha: false,
    showModalDataMaterial: false,
})
  
const mutations = {
    SET_DATAS: (state, payload) => {
        state.datas = payload;
        state.data_campanha = payload.filter(item => item.name === 'Campanha')[0];
        state.data_material = payload.filter(item => item.name === 'Materiais')[0];
        state.data_imagem = payload.filter(item => item.name === 'Imagens')[0];
    },
    SET_DATAS_LOADING: (state, payload) => {
        state.dataLoading = payload;
    },
    SET_SHOW_MODAL_DT_CAMPANHA: (state, payload) => {
        state.showModalDataCampanha = payload;
    },
    SET_SHOW_MODAL_DT_MATERIAL: (state, payload) => {
        state.showModalDataMaterial = payload;
    }
}

const actions = {
    async fetchDates({commit}){
        try{
            commit('SET_DATAS_LOADING', true)
            const response = await DataService.get_datas();
            commit('SET_DATAS', response.data)
            commit('SET_DATAS_LOADING', false)
        } catch (error) {
            commit('SET_DATAS_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDataCampanha({commit}, data){
        try{
            commit('SET_DATAS_LOADING', true);
            const response = await DataService.update_dtCampanha(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_DATAS_LOADING', false);
            return true;
        }catch(error){
            commit('SET_DATAS_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
    async registerDataMaterial({commit}, data){
        try{
            commit('SET_DATAS_LOADING', true);
            const response = await DataService.update_dtMaterial(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_DATAS_LOADING', false);
            return true;
        }catch(error){
            commit('SET_DATAS_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    }
}

const getters = {
    getDatas: state => state.datas,
    getDataCampanha: state => state.data_campanha,
    getDataMaterial: state => state.data_material,
    getDataImagem: state => state.data_imagem,
    getDataLoading: state => state.dataLoading,
    getShowModalDataCampanha: state => state.showModalDataCampanha,
    getshowModalDataMaterial: state => state.showModalDataMaterial,
}

export default {
    state,
    getters,
    mutations,
    actions
};