import AuthService from '../../services/AuthService'
import router from '../../router'
// import axios from 'axios'

const state = () => ({
    user_confirmation: false,
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: (() => {
        try {
            return JSON.parse(localStorage.getItem('user')) || null
        } catch (error) {
            localStorage.removeItem('user');
            return null;
        }
    })(),
    userSocio: false,
})
    
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    REGISTER_USER: (state) => {
        state.user_confirmation = true;
    },
    SET_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        
        state.isAuthenticated = true;
        state.token = payload.token
        state.user = payload.user
        if(router.currentRoute.value.path === '/auth' || router.currentRoute.value.path === '/'  || !state.redirect || state.redirect == ''){
            if(state.user  && state.user.accounType == 'adm'){
                router.push({path: `/dashboardADM`});
            } else{
                router.push({path: `/dashboard`});
            }
        }else {
            router.push({path: state.redirect});
            state.redirect = null;
        }
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('user', JSON.stringify(payload.user));
        localStorage.setItem('token', payload.token)
        state.user = payload.user;
        state.token = payload.token
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('likedItems');
        localStorage.removeItem('notifications');
        localStorage.removeItem('municipios');

        state.orders = [];
        state.user = null;
        state.token = null;
        state.cart = [];
        state.liked = [];
        state.notifications = [];
        state.cidades = [];
        state.currentSteps = [];
        state.isAuthenticated = false;
        state.products = [];
        state.product = null;

        if(router.currentRoute.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    REDEFINIR_SENHA(state){
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('likedItems');
        localStorage.removeItem('notifications');
        localStorage.removeItem('municipios');

        state.orders = [];
        state.user = null;
        state.token = null;
        state.cart = [];
        state.liked = [];
        state.notifications = [];
        state.cidades = [];
        state.currentSteps = [];
        state.isAuthenticated = false;
        state.products = [];
        state.product = null;

        router.push({path: `/password-reset`});
    },
    SET_USER_PROFILE_IMAGE(state, imageUrl) {
        state.user.image = imageUrl;
    },
}

const actions = {
    async loginUser({commit, dispatch}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            if(response.data.user.status == 'pendente'){
                dispatch('sendNewAccount', response.data.user, { root: true })
                commit('REGISTER_USER'); 
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            } else if(response.data.user.status == 'ativa'){
                commit('SET_USER', response.data);
            }
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerUserAgain({commit, dispatch}, credentials){
        try{
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            if(response.data.user.status == 'pendente'){
                dispatch('sendNewAccount', response.data.user, { root: true })
            }
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT')
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchUser({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getUser();
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchDataUser({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getDataUser();
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateUser({commit, state}){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.update(state.user);
            await commit('AUTH_USER', response.data);
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async verifyUser({state, commit}){
        try{
            if (state.user == null) return false
            if (state.user.order_status == 'Inadimplente') {
                commit('SET_ALERT', {
                    heading: 'Erro',
                    message: "Existem pendências em seu cadastro. Para regularizá-las, entre em contato com a Secretaria da SBU"
                });
                return false
            } else{
                return true;
            }
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async confirmUser({commit}, credentials){
        try{
            commit('SET_LOADING_USER', true);
            const response = await AuthService.confirmUser(credentials.token);
            setTimeout(() => {
                if(response.data.user.status == 'ativa'){
                    commit('SET_ALERT', {
                        heading: 'success',
                        message: response.data.message
                    });
                    commit('SET_USER', response.data);
                }
            }, 3000);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            setTimeout(() => {
                commit('LOG_USER_OUT');
            }, 2000);
        }
    }
}

const getters = {
    getUser: state => state.user,
    getUserState: state => state.user.Entidade_Addresses[0] || state.user.Entidade_Addresses,
    getLoadingUser: state => state.loadingUser,
    getConfirmation: state => state.user_confirmation,
}

export default {
    state,
    getters,
    mutations,
    actions
};