import Api from './Api'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`entidades?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getTotal() {
        return Api().get(`entidades/total`);
    },
    getOne(id) {
        return Api().get(`entidades/${id}`);
    },
    getComplementar(cnpj) {
        return Api().post(`complementar`, {cnpj});
    }
}