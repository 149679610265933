import EventoService from '../../services/EventoService'

const state = () => ({
    event: null,
    pos_event: {
        idEvent: null,
        images: [],
        description: null
    },
    events: [],
    eventsByState: [],
    eventsLoading: false,
    showModalEvent: false,
    pages: 0,
    page: 1,
    totalEvents: null,
    totalEventsState: null,
    stateSearch: {
        name: '',
        sigla: ''
    },
    pagesByState: 0,
    pageByState: 1,
    totalByState: null
})
  
const mutations = {
    SET_EVENT: (state, payload) => {
        state.event = payload.evento;
    },
    SET_EVENTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.events = payload.itens
        }else{
            state.events = [...state.events, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_EVENTS_BY_STATE: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.eventsByState = payload.itens
        }else{
            state.eventsByState = [...state.eventsByState, ...payload.itens]
        }
        state.pageByState =  payload.pageNumber
        state.pagesByState =  payload.pages
        state.totalByState =  payload.total
    },
    SET_EVENTS_STATE_SEARCH: (state, payload) => {
        state.stateSearch = payload;
    },
    SET_EVENTS_LOADING: (state, payload) => {
        state.eventsLoading = payload;
    },
    SET_SHOW_MODAL_EVENTS: (state, payload) => {
        state.showModalEvent = payload;
    },
    SET_EVENTS_STATE: (state, payload) => {
        state.totalEventsState = payload
    },
    SET_TOTAL_EVENTS: (state, payload) => {
        state.totalEvents = payload
    },
}

const actions = {
    async registerEvents({commit}, dados_Evento){
        try{
            commit('SET_EVENTS_LOADING', true);
            const response = await EventoService.create(dados_Evento);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_EVENTS_LOADING', false);
            return true;
        }catch(error){
            commit('SET_EVENTS_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
    async registerPosEvents({ commit }, {idEvent, description, imagens}) {
        try {
            commit('SET_EVENTS_LOADING', true);
            const formData = new FormData();
            formData.append('description', description);
            formData.append('idEvent', idEvent);

            for (let i = 0; i < imagens.length; i++) {
                formData.append('images', imagens[i]);
            }
            const response = await EventoService.imagesPos(formData);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message,
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            commit('SET_EVENTS_LOADING', false);
            return true;
        } catch (error) {
            commit('SET_EVENTS_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message,
            });
            return false;
        }
    },
    async fetchEvents({commit}, { keyword = '', pageNumber = 1, sortBy = 'data', myEvents = true }){
        try{
            commit('SET_EVENTS_LOADING', true)
            const response = await EventoService.get(keyword, pageNumber, sortBy, myEvents);
            commit('SET_EVENTS', response.data)
            commit('SET_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_EVENTS_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchEventsById({commit}, idEntidade){
        try{
            commit('SET_EVENTS_LOADING', true)
            const response = await EventoService.getById(idEntidade);
            commit('SET_EVENTS', response.data)
            commit('SET_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_EVENTS_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchEvent({commit}, id){
        try {
            commit('SET_EVENTS_LOADING', true);
            const response = await EventoService.getOne(id);
            commit('SET_EVENT', response.data);
            commit('SET_EVENTS_LOADING', false);
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalEvents({commit}){
        try{
            commit('SET_EVENTS_LOADING', true)
            const response = await EventoService.getTotal();
            commit('SET_TOTAL_EVENTS', response.data)
            commit('SET_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_EVENTS_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalEventsState({commit}){
        try{
            commit('SET_EVENTS_LOADING', true)
            const response = await EventoService.getStateTotal();
            commit('SET_EVENTS_STATE', response.data)
            commit('SET_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_EVENTS_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchEventsByState({commit}, { state = '',  pageNumber = 1}){
        try{
            commit('SET_EVENTS_LOADING', true)
            const response = await EventoService.getEventsByState(state.sigla, pageNumber);
            commit('SET_EVENTS_BY_STATE',  response.data)
            commit('SET_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_EVENTS_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async validarDadosEvento({commit}, dataEvent){
        if (!dataEvent.local) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a Local do Evento.'})
            return;
        }
        if (!dataEvent.name) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a Nome do Evento.'})
            return;
        }
        if (!dataEvent.qnt_publico || dataEvent.qnt_publico <= 0) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o número.'})
            return;
        } else if (!/^\d+$/.test(dataEvent.qnt_publico)) {
            commit('SET_ALERT', { heading: 'error', message: 'Número inválido.'})
            return;
        }
        if (!dataEvent.qnt_empregados || dataEvent.qnt_empregados <= 0) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o número.'})
            return;
        } else if (!/^\d+$/.test(dataEvent.qnt_empregados)) {
            commit('SET_ALERT', { heading: 'error', message: 'Número inválido.'})
            return;
        }
        if (!dataEvent.acoes) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe as Ações do Evento.'})
            return;
        }
        if (!dataEvent.observacoes) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe as Observacoes do Evento.'})
            return;
        }
        return true;
    },
    async updateEvento({commit, dispatch}, data){
        try{
            commit('SET_EVENTS_LOADING', true)
            if(await dispatch('validarDadosEvento', data)){
                const response = await EventoService.update(data);
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_EVENTS_LOADING', false);
        }catch(error){
            commit('SET_EVENTS_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getEvent: state => state.event,
    getPosEvent: state => state.pos_event,
    getEvents: state => state.events,
    getEventsLoading: state => state.eventsLoading,
    getShowModalEvent: state => state.showModalEvent,
    getTotalEvents: state => state.totalEvents,
    getTotalEventsState: state => state.totalEventsState,
    getEventsState: state => state.eventsByState,
    getEventsStateSearch: state => state.stateSearch,
}

export default {
    state,
    getters,
    mutations,
    actions
};