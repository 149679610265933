import WordPress from '../../services/WordPressService'

const state = () => ({
    post: null,
    posts: [],
    wpLoading: false,
})
  
const mutations = {
    SET_WP: (state, payload) => {
        state.post = payload.posto;
    },
    SET_WPS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.posts = payload.itens
        }else{
            state.posts = [...state.posts, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_WPS_LOADING: (state, payload) => {
        state.wpLoading = payload;
    },
}

const actions = {
    async registerPostWordPress({commit}, data){
        try{
            commit('SET_WPS_LOADING', true);
            const response = await WordPress.createPostWP(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            commit('SET_WPS_LOADING', false);
            return true;
        }catch(error){
            commit('SET_WPS_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    }
}

const getters = {
    getPost: state => state.post,
    getPosts: state => state.posts,
    getWPLoading: state => state.wpLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};