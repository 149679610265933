import EventoService from '../../services/EventoService'

const state = () => ({
    posEvents: [],
    eventsPosLoading: false,
    totalPosEvents: null,
    pages: 0,
    page: 1,
    total: null,
})
  
const mutations = {
    SET_POS_EVENTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.posEvents = payload.itens
        }else{
            state.posEvents = [...state.posEvents, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_POS_EVENTS_LOADING: (state, payload) => {
        state.eventsPosLoading = payload;
    },
    SET_TOTAL_POS_EVENTS_PENDENTES: (state, payload) => {
        state.totalPosEvents = payload;
    }
}

const actions = {
    async fetchPosEvents({commit}, { keyword = '', pageNumber = 1, sortBy = 'data', myEvents = true }){
        try{
            commit('SET_POS_EVENTS_LOADING', true)
            const response = await EventoService.getPos(keyword, pageNumber, sortBy, myEvents);
            commit('SET_POS_EVENTS', response.data)
            commit('SET_POS_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_POS_EVENTS_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalPendentesPosEvents({commit}){
        try{
            commit('SET_POS_EVENTS_LOADING', true)
            const response = await EventoService.getTotalPendentesPos();
            commit('SET_TOTAL_POS_EVENTS_PENDENTES', response.data)
            commit('SET_POS_EVENTS_LOADING', false)
        } catch (error) {
            commit('SET_POS_EVENTS_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPosEvents: state => state.posEvents,
    getPosEventsLoading: state => state.eventsPosLoading,
    getTotalPosEvents: state => state.totalPosEvents,
}

export default {
    state,
    getters,
    mutations,
    actions
};