import AuthService from '../../services/AuthService'

const state = () => ({
    loadingRegister: false,
    userRegister: null,
    register:  JSON.parse(localStorage.getItem('register')) || {
        servicos: null,
        responsavel: null,
        acesso: null
    },
    registerSteps: localStorage.getItem('registerSteps') || 1
})
    
const mutations = {
    SET_LOADING_REGISTER: (state, payload) => {
        state.loadingRegister = payload
    },
    SET_USER_REGISTER(state, data) {
        state.userRegister = data;
    },
    LIMPAR_REGISTER(state) {
        localStorage.removeItem('register');
        localStorage.removeItem('registerSteps');

        state.userRegister = null;
        state.registerSteps = 1;
        state.register = {
            servicos: null,
            responsavel: null,
            acesso: null,
        };
    },
    SET_DADOS_ENTIDADE_COMPLEMENTAR(state, data) {
        state.register.servicos = 
        {
            cnpj: data.cnpj,
            razao: data.razaoSocial,
            zip:  data.zip,
            street:  data.street,
            number:  data.number,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            latitude: data.latitude,
            longitude: data.longitude
        },
        state.register.responsavel = {
            firstName: data.firstName,
            lastName: data.lastName,
            telefone_1: data.telefone1,
            telefone_2: data.telefone2,
        };
        state.register.acesso = {
            email: data.email,
        };
    },
    SET_DADOS_SERVICOS(state, data) {
        state.register.servicos = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_DADOS_RESPONSAVEL(state, data) {
        state.register.responsavel = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_DADOS_ACESSO(state, data) {
        state.register.acesso = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_STAGE_REGISTER(state, etapa) {
        state.registerSteps= etapa;
        localStorage.setItem('registerSteps', JSON.stringify(etapa));
    },
    SET_BACK_STAGE_REGISTER(state) {
        state.registerSteps = --state.registerSteps;
    },
    CLEAN_STAGE_REGISTER(){
        localStorage.removeItem('register');
    }
}

const actions = {
    async registerEntidade({commit, state, dispatch}){
        try{
            commit('SET_LOADING_REGISTER', true);
            const response = await AuthService.create(state.register);
            commit('SET_USER_REGISTER', response.data.user);
            await dispatch('sendNewAccount', response.data.user, { root: true })
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_STAGE_REGISTER', 4);
            commit('CLEAN_STAGE_REGISTER');
            commit('SET_LOADING_REGISTER', false);
            return true;
        }catch(error){
            commit('SET_LOADING_REGISTER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
    async registerDadosServicos({commit}, { data }){
        try{
            commit('SET_LOADING_REGISTER', true);
            commit('SET_DADOS_SERVICOS', data);
            commit('SET_STAGE_REGISTER', 2);
            commit('SET_LOADING_REGISTER', false);
        }catch(error){
            commit('SET_LOADING_REGISTER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosResponsavel({commit}, { data }){
        try{
            commit('SET_LOADING_REGISTER', true);
            commit('SET_DADOS_RESPONSAVEL', data);
            commit('SET_STAGE_REGISTER', 3);
            commit('SET_LOADING_REGISTER', false);
        }catch(error){
            commit('SET_LOADING_REGISTER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosAcesso({commit}, { data }){
        try{
            commit('SET_LOADING_REGISTER', true);
            commit('SET_DADOS_ACESSO', data);
            commit('SET_LOADING_REGISTER', false);
        }catch(error){
            commit('SET_LOADING_REGISTER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async validarDadosEntidade({commit}, dataUser){
        if (!dataUser.cnpj) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a CNPJ.'})
            return;
        }
        if (!dataUser.razaoSocial) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a Razão Social.'})
            return;
        }
        if (!dataUser.Entidade_Addresses[0].zip) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o CEP.'});
            return;
        }
        if (!/^\d{5}-\d{3}$/.test(dataUser.Entidade_Addresses[0].zip)) {
            commit('SET_ALERT', { heading: 'error', message: 'CEP inválido.'})
            return;
        }
        if (!dataUser.Entidade_Addresses[0].street) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a rua.'})
            return;
        }
        if (!dataUser.Entidade_Addresses[0].number) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o número.'})
            return;
        }
        if (!/^\d+$/.test(dataUser.Entidade_Addresses[0].number)) {
            commit('SET_ALERT', { heading: 'error', message: 'Número inválido.'})
            return;
        }
        if (!dataUser.Entidade_Addresses[0].neighborhood) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o bairro.'})
            return;
        }
        if (!dataUser.Entidade_Addresses[0].city) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a cidade.'})
            return;
        }
        if (!dataUser.Entidade_Addresses[0].state) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o estado.'})
            return;
        }
        if (!/^[A-Z]{2}$/.test(dataUser.Entidade_Addresses[0].state)) {
            commit('SET_ALERT', { heading: 'error', message: 'Estado inválido.'})
            return;
        }
        if (!dataUser.firstName) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o Nome.'});
            return;
        }
        if (!dataUser.lastName) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe a Sobrenome.'})
            return;
        }
        if (!dataUser.telefone1) {
            commit('SET_ALERT', { heading: 'error', message: 'Por favor, informe o Celular .'})
            return;
        }
        return true;
    },
    async updateEntidade({commit, dispatch}, data){
        try{
            commit('SET_LOADING_REGISTER', true);
            if(await dispatch('validarDadosEntidade', data)){
                const response = await AuthService.update(data);
                commit('SET_USER_REGISTER', response.data.user);
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_LOADING_REGISTER', false);
        }catch(error){
            commit('SET_LOADING_REGISTER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getUserRegister: state => state.userRegister,
    getLoadingRegister: state => state.loadingRegister,
    getRegisterSteps: state => state.registerSteps,
    getDataRegisters: state => state.register
}

export default {
    state,
    getters,
    mutations,
    actions
};