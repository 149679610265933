import AuthService from '../../services/AuthService'

const state = () => ({
    loadingPassword: false,
    password_slep: 1,
})
    
const mutations = {
    SET_LOADING_PASSWORD: (state, payload) => {
        state.loadingPassword = payload
    },
    SLIP_NEW_PASSWORD: (state, slep) => {
        state.password_slep = slep;
    },
}

const actions = {
    async newPessVerificarEmail({commit, dispatch}, userInput){
        try {
            commit('SET_LOADING_PASSWORD', true)
            const responseToken = await AuthService.newPassVerifyEmail(userInput);
            const email = responseToken.data.email; 
            const response = await dispatch('sendNewPassword', { userInput: email }, { root: true });
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SLIP_NEW_PASSWORD', 2)
            commit('SET_LOADING_PASSWORD', false)
            return response.data.email
        } catch (error) {
            commit('SLIP_NEW_PASSWORD', 1)
            commit('SET_LOADING_PASSWORD', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async newPessVerificarToken({commit}, token){
        try {
            commit('SET_LOADING_PASSWORD', true)
            const response = await AuthService.newPassVerifyToken(token);
            if(response.data.valido == true){
                commit('SLIP_NEW_PASSWORD', 3)
            }
            commit('SET_LOADING_PASSWORD', false)
        } catch (error) {
            commit('SET_LOADING_PASSWORD', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async submitNewPass({commit}, credentials){
        try {
            commit('SET_LOADING_PASSWORD', true)
            const response = await AuthService.newPass(credentials);
            if(response.data.user){
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
                if(response.data.user.status == 'ativa'){
                    setTimeout(() => {
                        commit('SET_USER', response.data);
                        commit('SLIP_NEW_PASSWORD', 1)
                    }, 3000);
                }
            }
            commit('SET_LOADING_PASSWORD', false)
        } catch (error) {
            commit('SET_LOADING_PASSWORD', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getLoadingPassword: state => state.loadingPassword,
    getPasswordSlep: state => state.password_slep,
}

export default {
    state,
    getters,
    mutations,
    actions
};