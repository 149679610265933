import Api from './Api'

export default {
    get_datas(){
        return Api().get(`datas/`);
    },
    update_dtCampanha(data){
        return Api().patch('datas/update-campanha', {data});
    },
    update_dtMaterial(data){
        return Api().patch('datas/update-material', {data});
    },
}