import NavBarService from '../../services/NavBarService'

const state = () => ({
    navbar: localStorage.getItem('navbar') ? JSON.parse(localStorage.getItem('navbar')) : [],
    navbarLoading: false
})
  
const mutations = {
    SET_NAVBAR: (state, payload) => {
        state.navbar = payload;
        localStorage.setItem('navbar', JSON.stringify(payload));
    },
    SET_NAVBAR_LOADING: (state, payload) => {
        state.navbarLoading = payload;
    }
}

const actions = {
    async fetchNavbar({commit}){
        try {
            commit('SET_NAVBAR_LOADING', true);
            const response = await NavBarService.get();
            commit('SET_NAVBAR', response.data);
            commit('SET_NAVBAR_LOADING', false);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getNavBar: state => state.navbar,
    getnavbarLoading: state => state.navbarLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};