// import axios from 'axios'

const state = () => ({
    loadingTermos: false,
    showModelTermos: false
})
  
const mutations = {
    SHOW_MODAL_TERMOS: (state, payload) => {
        state.showModelTermos = payload
    }
}

const actions = {
}

const getters = {
    getShowModalTermos: state => state.showModelTermos,
    getLoadingTermos: state => state.loadingTermos
}

export default {
    state,
    getters,
    mutations,
    actions
};