import ImageService from '../../services/ImageService'

const state = () => ({
    loadingImages: false,
    image: null,
    images: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_image: false
})
  
const mutations = {
    SET_IMAGE: (state, payload) => {
        state.image = payload
    },
    SET_IMAGES: (state, payload) => {
        state.images = payload
    },
    SET_LOADING_IMAGES: (state, payload) => {
        state.loadingImage = payload
    },
    SET_SHOW_MODAL_IMAGE: (state, payload) => {
        state.show_modal_image = payload
    },
}

const actions = {
    async fetchImage({commit}, id){
        try{
            commit('SET_LOADING_IMAGES', true)
            const response = await ImageService.getOne(id);
            commit('SET_IMAGE', response.data)
            commit('SET_LOADING_IMAGES', false)
        } catch (error) {
            commit('SET_LOADING_IMAGES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchImages({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_IMAGES', true)
            const response = await ImageService.get(keyword, pageNumber, order);
            commit('SET_IMAGES', response.data)
            commit('SET_LOADING_IMAGES', false)
        } catch (error) {
            commit('SET_LOADING_IMAGES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createImage({commit}, data){
        try{
            commit('SET_LOADING_IMAGES', true)
            const response = await ImageService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_IMAGE', false)
            commit('SET_LOADING_IMAGES', false)
        } catch (error) {
            commit('SET_LOADING_IMAGES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeImage({commit}, data){
        try{
            commit('SET_LOADING_IMAGES', true)
            const response = await ImageService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_IMAGES', false)
        } catch (error) {
            commit('SET_LOADING_IMAGES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getImage: state => state.image,
    getImages: state => state.images,
    getLoadingImage: state => state.loadingImage,
    getShowModalImage: state => state.show_modal_image
}

export default {
    state,
    getters,
    mutations,
    actions
};