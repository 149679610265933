import Api from './Api'

export default {
    get(keyword, pageNumber, sort, myEvents) {
        return Api().post(`events?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}&myEvents=${myEvents}`);
    },
    getPos(keyword, pageNumber, sort, myEvents) {
        return Api().post(`events/pos?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}&myEvents=${myEvents}`);
    },
    getById(idEntidade) {
        return Api().post(`events/entidade?idEntidade=${idEntidade}`);
    },
    getEventsByState(state, pageNumber) {
        return Api().get(`events/byStates?state=${state}&pageNumber=${pageNumber}`);
    },
    getOne(id) {
        return Api().get(`events/${id}`);
    },
    create(dados_Evento){
        return Api().post('events/register', dados_Evento);
    },
    getStateTotal() {
        return Api().get(`events/eventosPorEstado`);
    },
    getTotal() {
        return Api().get(`events/total`);
    },
    update(data_Evento){
        return Api().put(`events/${data_Evento.id}`, data_Evento);
    },
    imagesPos(formData){
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data', // Indica que estamos enviando FormData
            },
          };
      
        return Api().post(`events/registerPosEvent`, formData, config);
    },
    getTotalPendentesPos() {
        return Api().get(`events/pos/total-pendente`);
    },
}